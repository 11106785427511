import './App.css';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import ActualApp from './ActualApp';
import { gql, GraphQLClient } from 'graphql-request';


function App() {

  console.log('Consider creating a seperate field in the material list to deal with Zumieten.')

  const [isSortByArticleGroupsThenArticlesState, setIsSortByArticleGroupsThenArticlesState] = useState(true);
  
  const [materialListState, setMaterialListState] = useState(`00-0000.00
Gruppe§Anzahl§Bezeichnung§Barcode§Nummer§Lagerplatz§Anzahl Ref.§
§§Beschallungsanlage§§§§§
0§1§2§3§4§5§6§7
a§b§c§d§e§f§g§h`
.split('\n'));

  const auxGotRawArticleListRef = useRef('initA');

  const fetchErrorCounterRef = useRef(0);

  
  useEffect(() => {
    // localStorage.clear();
    // window.alert('localStorage cleared');
    console.clear();
    
    // put the job number prompt into a function and made the execution of which dependable on whether there is stuff in the localStorage or not
    var jobNumberVar;
    var sortingModeVar;

    // console.log(localStorage, 'currently localStorage is to be deleted from the start');

    localStorage.getItem('stringOfPackedArticles') || localStorage.setItem('stringOfPackedArticles', '["initA"]');
    localStorage.getItem('sortBy') || localStorage.setItem('sortBy', 'initA');
    localStorage.getItem('stringOfPackedSerialNumbers') || localStorage.setItem('stringOfPackedSerialNumbers', 'initA_');
    localStorage.getItem('rawArticleList') || localStorage.setItem('rawArticleList', 'initA');
    
    // auxGotRawArticleListRef.current = localStorage.getItem('rawArticleList') ?? 'initA23';
    if (localStorage.getItem('rawArticleList').length > 5) {
      auxGotRawArticleListRef.current = localStorage.getItem('rawArticleList')
    } else {
      localStorage.setItem('rawArticleList', 'initA');
    };

    // console.log(localStorage);


    jobNumberVar = prompt('Job Nummer??');

    if (localStorage.getItem('stringOfPackedArticles') !== '[initA]') {
      jobNumberVar = jobNumberVar.replace('.', '');
      jobNumberVar = jobNumberVar.replace('-', '');
      
      sortingModeVar = window.confirm(`
      Sortierung nach Artikelgruppe -> Artikelbezeichnung ==> OK
      Sortierung nach Artikelbezeichnung -> Artikelgruppe ==> Cancel`.replaceAll('  ', ''))
      ?
      sortingModeVar = true
      :
      sortingModeVar = false;
    };
    fetchMaterialList();


    // jobNumberVar = prompt('Job Nummer?');
    
    // const localStorageSOPA = localStorage.getItem('stringOfPackedArticles');
    
    // if (localStorageSOPA.includes('jobNumberVar') || localStorageSOPA == '["initA"]') {
    //   console.log('new job');
    //   jobNumberVar = jobNumberVar.replace('.', '');
    //   jobNumberVar = jobNumberVar.replace('-', '');
    //   fetchMaterialList();
      
    //   sortingModeVar = window.confirm(`
    //   Sortierung nach Artikelgruppe -> Artikelbezeichnung ==> OK
    //   Sortierung nach Artikelbezeichnung -> Artikelgruppe ==> Cancel`.replaceAll('  ', ''))
    //   ?
    //   sortingModeVar = true
    //   :
    //   sortingModeVar = false;
    // } else if (localStorageSOPA.includes(jobNumberVar)) {
    //   console.log('proceed');
    //   sortingModeVar = window.confirm(`
    //   Sortierung nach Artikelgruppe -> Artikelbezeichnung ==> OK
    //   Sortierung nach Artikelbezeichnung -> Artikelgruppe ==> Cancel`.replaceAll('  ', ''))
    //   ?
    //   sortingModeVar = true
    //   :
    //   sortingModeVar = false;
    // };


    setIsSortByArticleGroupsThenArticlesState(sortingModeVar);
    setIsShowActualAppState(true);

    // async function actuallyDoTheFetch() {
    //   const url = process.env.REACT_APP_ENDPOINT;
    //   const token = process.env.REACT_APP_AUTH;
    
    //   const graphQLClient = new GraphQLClient(url, {
    //     headers: {
    //       "Authorization": token
    //     }
    //   });
    
    //   const materialListQuery = gql`
    //   query MyQuery {
    //   job(where: {jobNumberSlug: "00123498"}) {
    //     id
    //       jobMaterial
    //       jobNumberName
    //       jobNumberSlug
    //     }
    //   }
    //   `
    //   const fetchedMaterialListObject = graphQLClient.request(materialListQuery);
    //   const fetchedMaterialListObjectArray = Object.entries(fetchedMaterialListObject); // [0][1].jobMaterial;
        
    //     return {
    //       props: {
    //         fetchedMaterialListObjectArray
    //       }
    //     };
    //   };
    

    // function fetchMaterialList() {
    //   console.log('gone fetching');
      
    //   const actualFetchResponse = actuallyDoTheFetch();
    //   try {
    //     if (actualFetchResponse) {
    //       console.table(actualFetchResponse.fetchedMaterialListObjectArray);
    //       const auxMaterialList = actualFetchResponse.fetchedMaterialListObjectArray;
    //       console.log(auxMaterialList);
    //       localStorage.setItem('rawArticleList', auxMaterialList);
    //       setMaterialListState(auxMaterialList);  
    //     }
    //   } catch (error) {
    //     console.error(error);
    //   }
    // };
    // fetchMaterialList();

    async function fetchMaterialList() {
      console.log('go async');
      const url = process.env.REACT_APP_ENDPOINT;
      const token = process.env.REACT_APP_AUTH;
      
      const graphQLClient = new GraphQLClient(url, {
        headers: {
          "Authorization": token
        }
      });
      
      const materialListQuery = gql`
        query MyQuery {
          job(
            where: {
              jobNumberSlug: "${jobNumberVar}"
            }
          )
          {
            id
            jobMaterial
            jobNumberName
            jobNumberSlug
            log
          }
        }
      `;
      
      try {
        const fetchedMaterialListObject = await graphQLClient.request(materialListQuery);
        console.log(fetchedMaterialListObject);
        const auxMaterialList = Object.entries(await fetchedMaterialListObject)[0][1].jobMaterial.replaceAll('"', '´´');
        if (auxMaterialList) {
          console.table(await auxMaterialList);
          localStorage.setItem('rawArticleList', await auxMaterialList);
          setMaterialListState(await auxMaterialList);
          
          return {
            props: {
              auxMaterialList
            }}
        }
      } catch (error) {
        console.error(error);
        fetchErrorCounterRef.current = fetchErrorCounterRef.current + 1
      }
    };

    fetchMaterialList();
    
    },
  [fetchErrorCounterRef]);
  

  localStorage.setItem('sortBy', 'initA16');
  

  const [isShowActualAppState, setIsShowActualAppState] = useState(false);

  const [auxLocalStorageStringOfPackedArticlesState, setAuxLocalStorageStringOfPackedArticlesState] = useState(localStorage.getItem('stringOfPackedSerialNumbers') ?? 'initA66');

  
  const go = () => {

    localStorage.setItem('stringOfPackedArticles', '["initA70"]');
    localStorage.setItem('stringOfPackedSerialNumbers', 'initA71_');
    setAuxLocalStorageStringOfPackedArticlesState([localStorage.getItem('stringOfPackedSerialNumber'.split('_'))]);
    // setIsShowActualAppState(true);
    
  };


  // const toggleSortingMode = () => {
  //   setIsSortByArticleGroupsThenArticlesState(isSortByArticleGroupsThenArticlesState => !isSortByArticleGroupsThenArticlesState)
  //   const auxSortBy = isSortByArticleGroupsThenArticlesState;
  //   localStorage.setItem('sortBy', auxSortBy);
  //   console.log(`localStorage says ${localStorage.getItem('sortBy')}`);
  // }

  // setTimeout(() => console.log('take a second'), 3000); 

  return (
    <>
      {
        materialListState.length > 0 && isShowActualAppState
        ?
        ''
        :
        ''
        // <>
        //   <ToggleSortModeDiv
        //     onClick={toggleSortingMode}
        //     backgroundColor={isSortByArticleGroupsThenArticlesState ? 'green' : 'lightblue'}
        //   >
        //     {isSortByArticleGroupsThenArticlesState ? 'articleGroup --> article' : 'article --> articleGroup'}
        //   </ToggleSortModeDiv>
        // </>
      }

      {
      materialListState && isShowActualAppState
      ?
      <ActualApp 
        materialList={materialListState}
        sortBy={isSortByArticleGroupsThenArticlesState}
      />
      :
      <>
        ''
        {/* {materialListState && materialListState.map((item, key) => {return(<p key={key}>{item}</p>)})} */}
      </>
      }
      
      {/* {!isShowActualAppState && auxLocalStorageStringOfPackedArticlesState.length > 5 ? <button onClick={() => go()}>continue with current job</button> : ''} */}
    </>
  );
};
export default App;



const ToggleSortModeDiv = styled.div`
  width: 10rem;
  height: 3rem;
  border-radius: 4pk;
  background-color: ${props => props.backgroundColor};
  cursor: pointer;
`