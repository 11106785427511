import styled from "styled-components";

const AckDialog = ({keyProp, ackAndOk, resetPackedAmountState}) => {
    return (
        <>
            <Dialog>
                <Message>Packed amount does not match needed amount!</Message>
                <OopsAckButtonDiv color={'white'} backgroundColor={'red'} onClick={() => resetPackedAmountState()}>undo</OopsAckButtonDiv>
                <OopsAckButtonDiv color={'black'} backgroundColor={'limegreen'} onClick={() => ackAndOk(keyProp)}>accept anyway</OopsAckButtonDiv>
            [{keyProp}]
            </Dialog>
        </>

    )
};

export default AckDialog;

const Dialog = styled.div`
  background-color: orange;
  width: 25rem;
  height: 12rem;
  font-size: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
`;

const OopsAckButtonDiv = styled.div`
  background-color: ${props => props.backgroundColor};
  color: ${props => props.color};
  width: 7rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: red 1px solid;
  border-radius: 5px;
`;

const Message = styled.p`
  width: 20rem;
  color: red;
  font-size: 24px;
  font-weight: 700;
`;