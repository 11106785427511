/* eslint-disable eqeqeq */
import './App.css';
import { useEffect, useRef, useState } from 'react';
import listOfArticles from './assets/listOfArticlesInThisJob';
import styled from 'styled-components';
import AckDialog from './components/AckDialog';
import { gql, GraphQLClient } from 'graphql-request';


const stringOfStuffToBePacked = listOfArticles;


function ActualApp({ materialList, sortBy }) {
  

  // console.log('actual app has been started', materialList);

  var arrayOfArticleObjectsToBePacked = [];
  const [arrayOfArticleObjectsToBePackedState, setArrayOfArticleObjectsToBePackedState] = useState(['heinz ketchup']);
  const [currentItemNumberState, setCurrentItemNumberState] = useState(0);
  const [packedAmountState, setPackedAmountState] = useState('0');
  const [currentNeededAmountState, setCurrentNeededAmountState] = useState(666);
  const [currentArticleState, setCurrentArticleState] = useState('needful things');
  const [nextArticleState, setNextArticleState] = useState('[next]');
  const [isShowAckDialog, setIsShowACKDialog] = useState(false);
  const [isShowBigArticleText, setIsShowBigArticleText] = useState(true);
  const [arrayOfPackedArticlesState, setArrayOfPackedArticlesState] = useState([]);
  const [isShowHabeFertig, setIsShowHabeFertig] = useState(false);
  const [isOkButtonActive, setIsOkButtonActive] = useState(false);
  const [ackItemNumbersArrayState, setAckItemNumbersArrayState] = useState([]);
  const [isShowAckItemsDiv, setIsShowAckItemsDiv] = useState(false);
  const [arrayOfGotPackedSerialNumbersState, setArrayOfGotPackedSerialNumbersState] = useState(localStorage.getItem(['stringOfPackedSerialNumbers'.split('_')]));
  const [packedStuffFromLocalStorageState, setPackedStuffFromLocalStorageState] = useState(localStorage.getItem('stringOfPackedArticles'));
  const [isShowWobblerState, setIsShowWobblerState] = useState(true);
  const [lengthOfGroupsArrayState, setLengthOfGroupsArrayState] = useState(0);
  const [isShowFinalSendButtonState, setIsShowFinalSendButtonState] = useState(true);
  var isArrayNeedsToBeProcessedVar = true;
  let isCasesArticlesNeedToBeChanged = true;
  var now = new Date();
  const jobNumberRef = useRef('00-0000.00');
  const logRef = useRef('');
  
  useEffect(() => {

    // New idea: Why not take the array of articles that need to be packed - including groups!
    // Then the array will be sorted by nededAmount in order to move all the groups to the end of the array.
    // Afterwards the array needs to be sliced/spliced and sorted once again according to the sortBy mode.
    // That way only the articles themselves will be regarded, whereas the groups could be made left out.
    // On the other hand I have no idea whatsoever how to deal with the several auxiliary arrays and stuff up front.

    // console.log('AA useEffect running', materialList);

    // console.log('stringOfPackedArticles from localStorage: ', localStorage.getItem('stringOfPackedArticles'));
    let auxArrayOutOfPackedStuffFromLocalStorageState;
    try {
      auxArrayOutOfPackedStuffFromLocalStorageState = packedStuffFromLocalStorageState.length > 0 ? packedStuffFromLocalStorageState.split('\n') : [];
    // console.table('auxArrayOutOfPackedStuffFromLocalStorageState: ', auxArrayOutOfPackedStuffFromLocalStorageState);
    } finally {
      //
    };

    
    const handleBeforeUnload = event => {
      event.preventDefault();
      console.log('refresh prevented');
      let auxArrayOfPackedArticlesState = arrayOfPackedArticlesState.map(item => item.split('§'));
      auxArrayOfPackedArticlesState = auxArrayOfPackedArticlesState.sort((a, b) => a[1] - b[1]);
      setArrayOfPackedArticlesState(auxArrayOfPackedArticlesState);

      
    };
    window.addEventListener('beforeunload', handleBeforeUnload);


    // setArrayOfPackedArticlesState([]);
    // console.clear();
    

    materialList.length > 0 && localStorage.setItem('rawArticleList', materialList);
    console.log(localStorage.getItem('rawArticleList'));

    let arrayOfStuffToBePacked = materialList;
    console.log(typeof(materialList), materialList);

    try {
      arrayOfStuffToBePacked = materialList.split('\n')
      console.log('try', arrayOfStuffToBePacked);
    } catch {
      // arrayOfStuffToBePacked = localStorage.getItem('rawArticleList').split('§')
      console.log('catch', arrayOfStuffToBePacked);
    };
    console.log(arrayOfStuffToBePacked, materialList);

    jobNumberRef.current = arrayOfStuffToBePacked[0]; //.slice(0, 10); // obviously the first line of the copy&pasted stuff :)
    setIsShowWobblerState(false);
    
    const unsplitListOfStuffToBePacked = arrayOfStuffToBePacked.splice(2); //stringOfStuffToBePacked.split('\n').splice(2);
    console.table(unsplitListOfStuffToBePacked);
    let groupVar = 'haw-haw';
    var articleGroupVar = '';
    var serialNumberCounterVar = 1;
    var auxNeededAmountVar = 0;
    var auxArticleVar = null;
    var auxStorageVar = null;
    var auxOwnNumber = null;
    var auxArticleBarcode = null;

    const arrayOfGroups = [];
    const arrayOfGroupsShortened = [];
    const arrayOfArticleGroups = [];

    setCurrentItemNumberState(0);


    const processEachItemOfUnsplitListOfStuffToBePacked = (line, key) => {

      const aux = line.split('§'); // @ can no longer be used because of the @st12345 stuff

      console.log(aux);

      if (line === '') {
        articleGroupVar = '---';
        auxArticleVar = 'EOF 55';
        auxNeededAmountVar = '---';
        auxStorageVar = '---';

        isArrayNeedsToBeProcessedVar = false;
        return null;
      }      
      else if (aux[0] === '' & aux[1] === '') {
        arrayOfGroups.push(aux[2]);
        arrayOfGroupsShortened.push(aux[2].slice(0, aux[2].indexOf('|')));
        setLengthOfGroupsArrayState(arrayOfGroups.length);
        groupVar = aux[2];
        // console.log(87, groupVar);
        // console.table(arrayOfGroups);
        // console.table(arrayOfGroupsShortened);
        // return null;
        articleGroupVar = 'some group';
        arrayOfArticleGroups.push(articleGroupVar);
        auxNeededAmountVar = '999';
        auxArticleVar = 'foo';
        auxArticleBarcode = 'bar';
        auxOwnNumber = '1234567.89';
        auxStorageVar = 'somewhere';

      }
      else if (aux[0] === '' & aux[1] !== '') {
        auxNeededAmountVar = aux <= 1 ? '1' : aux[1];
        // groupVar = aux[2];
        auxArticleVar = aux[2];
        auxArticleBarcode = aux[3];
        auxOwnNumber = aux[4];
        auxStorageVar = aux[5].length > 7 ? aux[5] : 'n/a';
        
      }
      else if (aux[0] !== '') {
        articleGroupVar = aux[0];
        arrayOfArticleGroups.push(articleGroupVar);
        auxNeededAmountVar = aux <= 1 ? '1' : aux[1];
        auxArticleVar = aux[2];
        auxArticleBarcode = aux[3];
        auxOwnNumber = aux[4];
        auxStorageVar = aux && aux[5].length > 7 ? aux[5] : 'n/a';
      };


      arrayOfArticleObjectsToBePacked.push({
        'articleGroup': articleGroupVar,
        'neededAmount': auxNeededAmountVar ?? 0.1,
        'article': auxArticleVar ?? 'n/a',
        'group': groupVar,
        'ownNumber': auxOwnNumber,
        'articleBarcode': auxArticleBarcode,
        'storage': auxStorageVar.split('.') ?? 'n/a', // that's important for sorting and the storage component
        'serialNumberCounter': serialNumberCounterVar});

      serialNumberCounterVar++;

      // console.table(arrayOfArticleObjectsToBePacked);
    };



    isArrayNeedsToBeProcessedVar && unsplitListOfStuffToBePacked.map((item, key) => {
      processEachItemOfUnsplitListOfStuffToBePacked(unsplitListOfStuffToBePacked[key], key)
      // console.log(item, key, isArrayNeedsToBeProcessedVar);
    });

    isCasesArticlesNeedToBeChanged && arrayOfArticleObjectsToBePacked.forEach((item) => item.articleGroup = item.articleGroup === 'Cases' ? `ZZYYXX_${item.articleGroup}` : item.articleGroup);
    isCasesArticlesNeedToBeChanged = false;

    // read lines 44ff.
    console.clear();
    // console.table(arrayOfArticleObjectsToBePacked);
    arrayOfArticleObjectsToBePacked.sort((a, b) => a.neededAmount.localeCompare(b.neededAmount));
    // console.table(arrayOfArticleObjectsToBePacked);
    // this aux array is meant to only contain the actual articles to be packed; groups are to be sorted out
    const auxArrayOfArticlesToBePackedOnlyThatIsWithoutGroups = [];
    arrayOfArticleObjectsToBePacked.forEach(item => item['neededAmount'] != 999 && auxArrayOfArticlesToBePackedOnlyThatIsWithoutGroups.push(item));
    // console.log(auxArrayOfArticlesToBePackedOnlyThatIsWithoutGroups);
    arrayOfArticleObjectsToBePacked = auxArrayOfArticlesToBePackedOnlyThatIsWithoutGroups;
    // console.table(arrayOfArticleObjectsToBePacked);

    sortBy
    ?                           
    arrayOfArticleObjectsToBePacked.sort((a, b) => a.articleGroup.localeCompare(b.articleGroup) || b.group - a.group)
    :
    arrayOfArticleObjectsToBePacked.sort((a, b) => a.group.localeCompare(b.group) || b.articeGroup - a.articeGroup)
    ;

    console.log('colors');
    // console.table(arrayOfArticleObjectsToBePacked);

    try {
      setArrayOfArticleObjectsToBePackedState(arrayOfArticleObjectsToBePacked);
      console.log(arrayOfArticleObjectsToBePacked[0]['article']);
      setCurrentArticleState(arrayOfArticleObjectsToBePacked[0]['article']); // sets the first article
      setCurrentNeededAmountState(arrayOfArticleObjectsToBePacked[0]['neededAmount']); // sets the first needed amount
      setNextArticleState(arrayOfArticleObjectsToBePacked[1]['article']);
    } catch {
      console.log('materialList nya.')
    }
    finally {
      // setIsShowWobblerState(false);
    };
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };

  }, [materialList]);

  const keypadButtonsNumbersArray = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];

  const addDigitToPackedAmount = (digit) => {
    isOkButtonActive || setIsOkButtonActive(true);
    packedAmountState != 0 ? setPackedAmountState([packedAmountState, digit].join('')) : setPackedAmountState(digit);
    // console.log(packedAmountState);
  };

  const removeDigitFromPackedAmount = () => {
    packedAmountState.length != 1 ? setPackedAmountState(packedAmountState.slice(0, -1)) : setPackedAmountState('0');
    packedAmountState == '0' && setIsOkButtonActive(false);
    // console.log(packedAmountState);
  };

  const addDigitZeroToPackedAmount = () => {
    packedAmountState != 0 ? setPackedAmountState([packedAmountState, '0'].join('')) : setPackedAmountState('0');
    setIsOkButtonActive(true);
    // console.log(packedAmountState);
  };

  currentNeededAmountState || setCurrentNeededAmountState(arrayOfArticleObjectsToBePackedState[currentItemNumberState]);


  const proceed = (snc) => {
    console.log(191, snc, 'proceed');
    // const auxGotStringOfPackedSerialNumbers = localStorage.getItem('stringOfPackedSerialNumbers');
    arrayOfArticleObjectsToBePackedState[currentItemNumberState + 1] && setCurrentNeededAmountState(arrayOfArticleObjectsToBePackedState[currentItemNumberState + 1]['neededAmount'] ?? '193 tomato');
    setCurrentItemNumberState(currentItemNumberState => currentItemNumberState + 1);
    const auxCurrentItemNumber = currentItemNumberState;
    setCurrentArticleState(arrayOfArticleObjectsToBePackedState[auxCurrentItemNumber]['article']);

  };

  const ok = (key, snCounter, iAmAGroupTrigger) => {

    var auxCurrentNeededAmount = arrayOfArticleObjectsToBePackedState[key]['neededAmount'];
    var auxGotStringOfPackedSerialNumbers = localStorage.getItem('stringOfPackedSerialNumbers');

    setIsShowACKDialog(false);

    // this if-block is the default behaviour
    if (snCounter == undefined) { 
      const auxCurrentItemNumber = currentItemNumberState != nextArticleState ? currentItemNumberState + 1 : currentItemNumberState;
      const auxNextItemNumber = currentItemNumberState + 2;
      setCurrentItemNumberState(auxCurrentItemNumber);
      let auxGroup;
      
      try {
        // this try-catch-finally-block only deals with the group of the item
        auxGroup = arrayOfArticleObjectsToBePackedState[auxCurrentItemNumber - 1]['group'];
      } catch {
        console.log('There is some trouble with the group of the last item in the sorted array.');
      } finally {
        console.log(auxGroup);
      };

      try {
        setCurrentArticleState(arrayOfArticleObjectsToBePackedState[auxCurrentItemNumber]['article']);
      } catch {
        console.log('There is no more item to be setCurrentArticleState!!');
        setCurrentArticleState('ACME');
        setIsShowHabeFertig(true);
        // auxGroup = 'n/a';
      };

    
      console.log('from lS:', localStorage.getItem('stringOfPackedArticles'));
      const auxStringOfPackedArticles = JSON.parse(localStorage.getItem('stringOfPackedArticles')); // : arrayOfPackedArticlesState;
      console.log('auxSOPA from lS:', auxStringOfPackedArticles);

      let auxArrayOfPackedArticles = auxStringOfPackedArticles[0] === 'initA37' ? [] : auxStringOfPackedArticles; //.split('\u00A4');
      console.table(auxArrayOfPackedArticles);

      const auxSerialNumberCounter = arrayOfArticleObjectsToBePackedState[key]['serialNumberCounter'];
      const currentPackedArticle = `${packedAmountState == currentNeededAmountState ? 'ok' : '!!!'} .. ${packedAmountState.padStart(3, '\u00A0')} (${currentNeededAmountState.padStart(3, '\u00A0')}) x ${currentArticleState}[${auxSerialNumberCounter}] .. ${now.toLocaleString()}§${auxSerialNumberCounter}§${auxGroup}¤`;
      auxArrayOfPackedArticles.push(currentPackedArticle);
      console.log(auxArrayOfPackedArticles);
      const auxPreResortedArrayOfPackedArticles = auxArrayOfPackedArticles.sort((a, b) => a[1] - b[1]);
      console.table(auxPreResortedArrayOfPackedArticles);
      let jason = JSON.stringify(auxPreResortedArrayOfPackedArticles);
      console.log(jason);
      localStorage.setItem('stringOfPackedArticles', jason);
      setArrayOfPackedArticlesState(auxPreResortedArrayOfPackedArticles); //auxArrayOfPackedArticles);
      setPackedStuffFromLocalStorageState(JSON.parse(localStorage.getItem('stringOfPackedArticles')));

      console.log(282, arrayOfArticleObjectsToBePackedState[currentItemNumberState]['article']);

      try {
        // this if-block happens only when there is more than one article left, so that the next-article-state can be assigned.
        if (currentItemNumberState < arrayOfArticleObjectsToBePackedState.length) {
          console.log(320, key);
          const auxNextArticle = arrayOfArticleObjectsToBePackedState[auxNextItemNumber]['article'] ? arrayOfArticleObjectsToBePackedState[auxNextItemNumber]['article'] : 'EOF 159';
          setNextArticleState(auxNextArticle);
          // arrayOfArticleObjectsToBePackedState[currentItemNumberState + 1] && setCurrentNeededAmountState(arrayOfArticleObjectsToBePackedState[currentItemNumberState + 1]['neededAmount'] ?? '237 tomato');
        };
      } catch (error) {
        // this is not actually a catch error; this only takes place when the last article was reached
        console.log(327, 'not really an error: end of arrayOfArticleObjectsToBePackedState seems to be reached');
        setNextArticleState('n/a');
        // arrayOfArticleObjectsToBePackedState[currentItemNumberState + 1] && setCurrentNeededAmountState(arrayOfArticleObjectsToBePackedState[currentItemNumberState + 1]['neededAmount'] ?? '242 tomato');
      } finally {
        setIsOkButtonActive(false);
        arrayOfArticleObjectsToBePackedState[currentItemNumberState + 1] && setCurrentNeededAmountState(arrayOfArticleObjectsToBePackedState[currentItemNumberState + 1]['neededAmount'] ?? '248 tomato');
        localStorage.setItem('stringOfPackedSerialNumbers', `${auxGotStringOfPackedSerialNumbers}_${auxSerialNumberCounter}`);
        console.log(localStorage.getItem('stringOfPackedSerialNumbers'));
        let auxSerialNumberFromLocalStorageThing = localStorage.getItem('stringOfPackedSerialNumbers');
        console.log(auxSerialNumberFromLocalStorageThing);
        auxSerialNumberFromLocalStorageThing = auxSerialNumberFromLocalStorageThing.split('_').splice(2);
        auxSerialNumberFromLocalStorageThing = auxSerialNumberFromLocalStorageThing.map(item => parseInt(item, 10));
        console.log(auxSerialNumberFromLocalStorageThing);
        setArrayOfGotPackedSerialNumbersState(auxSerialNumberFromLocalStorageThing);
        setPackedAmountState('0');

        // return;
      };

      return;
    }
    else if (snCounter) {
      setCurrentItemNumberState(currentItemNumberState => currentItemNumberState + 1);
      console.log('proceed to next item');
      return;
    }
    // else if (iAmAGroupTrigger) {
    //   setCurrentItemNumberState(currentItemNumberState => currentItemNumberState + 1);
    //   console.log('This item is a group. Onto the next item');
    //   return;
    // }
    else {

      console.log(snCounter, packedAmountState, auxCurrentNeededAmount, 'too many or not enough OR item has already been packed!');

    };
  
  };

  const unsortArrayOfPackedArticles = () => {
    logRef.current = JSON.parse(localStorage.getItem('stringOfPackedArticles')).join('§');
    logRef.current = logRef.current.replace('/', '_');
    console.log('change back to normal order', logRef.current);
    let auxArrayFromLogRefCurrent = logRef.current.split('","');
    console.table(auxArrayFromLogRefCurrent);
    auxArrayFromLogRefCurrent = auxArrayFromLogRefCurrent[0].split('¤§');
    console.table(auxArrayFromLogRefCurrent);
    // auxArrayFromLogRefCurrent = auxArrayFromLogRefCurrent.split('§');
    console.table(auxArrayFromLogRefCurrent);
    const auxArrayOfPackedArticles = localStorage.getItem('stringOfPackedArticles').split('¤'); // arrayOfPackedArticlesState;
    console.log(auxArrayOfPackedArticles);
    const auxSortedArray = auxArrayOfPackedArticles.sort((a, b) => a.serialNumberCounter - b.serialNumberCounter);
    console.table(auxSortedArray);
    // logRef.current = auxSortedArray;
    setArrayOfPackedArticlesState(auxSortedArray);
    const auxTomacco = arrayOfArticleObjectsToBePackedState;
    console.table(auxTomacco); // arrayOfPackedArticlesState);
    setIsShowHabeFertig(true);
    sendDataAndClearLocalStorage();
    setIsShowFinalSendButtonState(false);
  };
  
  const ackAndOk = (key) => {
    const auxFormerAckItemNumbersArray = ackItemNumbersArrayState;
    const auxNewAckItemNumbersArray = [...auxFormerAckItemNumbersArray, arrayOfArticleObjectsToBePackedState[currentItemNumberState]['serialNumberCounter']];
    setAckItemNumbersArrayState(auxNewAckItemNumbersArray);
    // console.log(ackItemNumbersArrayState);
    ok(key);
  }


  const checkIfNeededAmountAndPackedAmountAreEqual = () => {
    // console.log(187, arrayOfArticleObjectsToBePackedState[currentItemNumberState]['neededAmount'], packedAmountState);
    if (arrayOfArticleObjectsToBePackedState[currentItemNumberState]['neededAmount'] === packedAmountState) {
      // console.log(189);
      ok(currentItemNumberState);
      return;
    } else if (arrayOfArticleObjectsToBePackedState[currentItemNumberState]['article'] === 'foo') {
      console.log('foo detected');
      ok(null, null, true);
    }
    else {
      console.log(193);
      setIsShowACKDialog(true);
    }
  };

  const resetPackedAmountState = () => {
    setPackedAmountState(0);
    setIsShowACKDialog(false);
  };

async function sendDataAndClearLocalStorage() {
  // window.alert('This is just a mockup function, but at least it clears the localStorage. Good bye.');
  const url = process.env.REACT_APP_ENDPOINT;
  const token = process.env.REACT_APP_AUTH;

  const slugStringReplacements = [
    ['-', ''],
    ['.', '']
  ];

  const slug = slugStringReplacements.reduce((acc, [oldStr, newStr]) => {
    return acc.replace(oldStr, newStr)
  }, jobNumberRef.current);
  
  console.log(slug);
  

  const graphQLClient = new GraphQLClient(url, {
    headers: {
      "Authorization": token,
    }
  });

  const mutateLogQuery = gql`
    mutation MyMutation {
      updateJob(
        data: {
          log: "${new Date().toString()}§${logRef.current}"
        },
        where: {
          jobNumberSlug: "${slug}"
        }
      )
      {
        log
      }
      publishJob(
        where: {
          jobNumberSlug: "${slug}"
        }
      )
      {
        jobNumberSlug
      }
    }
  `.replaceAll('  ', '').replaceAll('\n', '');

  try {
    const uploadAndPublishLogData = await graphQLClient.request(mutateLogQuery);
    console.log(await uploadAndPublishLogData);
    console.log(localStorage);

    localStorage.clear();
    console.log('data sent away');

    return {
      props: {
        uploadAndPublishLogData
      }
    }
  } catch (error) {
    console.log(error);
  } finally {
    console.log('good buy');
  };

};


const toggleLogWindow = () => {
  // let aux = localStorage.getItem('stringOfPackedArticles');
  // aux = JSON.parse(aux);
  // console.log(aux);
  // try {
  //   aux.sort((a, b) => a.slice(a.indexOf('[') + 1, a.indexOf(']')) - b.slice(b.indexOf('[') + 1, b.indexOf(']')));
  //   aux = aux.join('')
  // } catch {
  //   console.log('no array')
  // };
  // aux = aux.replaceAll(',ok', 'ok');
  // aux = aux.replaceAll(',!!', '!!');
  // aux = aux.replaceAll('\u00A4', '\n');
  setIsShowAckItemsDiv(false);
};


  return (
    <div className="App">
      <header
      // className="App-header"
      />
      <MainDiv>
        <BoldP>Job-Nummer: {isShowWobblerState ? 'W O B B L E W O B B L E' : jobNumberRef.current}</BoldP>
        {/* {isShowWobblerState ? <h1>Wobbler</h1> : ''} */}
        {/* {arrayOfGotPackedSerialNumbersState} */}
        {/* {arrayOferialNumbersOfPackedItems.map((item) => {return item})}; */}
        <CurrentArticleDiv>
            {/* <p>currentItemNumberState: {currentItemNumberState}</p> */}
            {/* <p>currentNeededAmountState: {currentNeededAmountState}</p>
            <p>nextArticleState: {nextArticleState}</p> */}


            {/* <button onClick={() => setCurrentItemNumberState(arrayOfArticleObjectsToBePackedState.length - 2)}>goto last item</button>
            <button onClick={() => setCurrentItemNumberState(currentItemNumberState + 1)}>+</button>
            <button onClick={() => setCurrentItemNumberState(currentItemNumberState - 1)}>-</button> */}

            {/* {currentItemNumberState} */}

            {arrayOfArticleObjectsToBePackedState.map((item, key) => {
              return(
                <>
                  {/* {item.serialNumberCounter} */}
                  {/* {key == currentItemNumberState && <p>currentItemNumberState: {currentItemNumberState} | key: {key} | SNC: {item.serialNumberCounter}</p>} */}
                      {
                        !arrayOfGotPackedSerialNumbersState.includes(item.serialNumberCounter)
                        && 
                        key == currentItemNumberState
                        // &&
                        // item.neededAmount != '999'
                        ?
                        <>

                          <ArticleInformationDiv key={key + 100}>
                            Artikelgruppe: {item.articleGroup}
                          </ArticleInformationDiv>


                          <ArticleInformationDiv>
                            <span>Lagerort: {item.storage}</span>
                            <span>Art.Barcode: {item.articleBarcode} | eig. Nummer: {item.ownNumber}</span>
                          </ArticleInformationDiv>

                          <ArticleInformationDiv key={key + 102}>
                            <FatP key={10000 + key}>
                              Gruppe:
                            </FatP>
                            {item.group && item.group.length > 40 ? `${item.group.slice(0, 40)}[...]` : `${item.group}`}
                          </ArticleInformationDiv>

                          <ArticleInformationDiv key={key + 101}>
                            <NeededArticleDiv
                              key={1000 + key}
                              onClick={() => setIsShowBigArticleText(!isShowBigArticleText)}
                              fontSize={isShowBigArticleText ? 24 : 14}
                              >
                              {item.article}
                            </NeededArticleDiv>
                          </ArticleInformationDiv>

                          <NeededAmountDiv>
                            <div>benötigte Anzahl:</div>
                            <div>{item.neededAmount}</div>
                          </NeededAmountDiv>

                          <PackedAmountDiv>
                            <div>gepackte Anzahl:</div>
                            <div>{packedAmountState}</div>
                          </PackedAmountDiv>
                          
                        </>  
                        :
                        ''
                        }
                        {/* <HabeFertigDiv>
                        {isShowHabeFertig && key == currentItemNumberState ? <h1>habe fertig</h1> : ''}
                          {isShowHabeFertig // && key == currentItemNumberState
                          ?
                          arrayOfPackedArticlesState.map((item, key) => {
                            return (
                            <div>
                              <p key={key}>{item}</p>
                            </div>
                          );
                          })
                          :
                          ''}
                        </HabeFertigDiv> */}
                        <div>
                          
                          {isShowAckDialog && key == currentItemNumberState
                          ?
                          <>
                            <AckDialog ackAndOk={ackAndOk} resetPackedAmountState={resetPackedAmountState} keyProp={key} />
                          </>  
                          :
                          ''
                          }

                          {isShowAckDialog || key !== currentItemNumberState || isShowHabeFertig // || item.article === 'foo' // currentItemNumberState == arrayOfArticleObjectsToBePackedState.length
                          ?
                          ''
                          :
                          <NumBlockDiv>
                            <KeypadButtonFlexWrap>
                              {keypadButtonsNumbersArray.map((item, index) => {
                                return (<KeypadButton key={index} onClick={() => addDigitToPackedAmount(item)}>{item}</KeypadButton>)
                              })}
                              <KeypadButton key={11} onClick={() => removeDigitFromPackedAmount()}>←</KeypadButton>
                              <KeypadButton key={12} onClick={() => addDigitZeroToPackedAmount()}>0</KeypadButton>
                              {
                                isOkButtonActive || !arrayOfGotPackedSerialNumbersState.includes(item.serialNumberCounter)
                                ?
                                  <KeypadButton key={13} onClick={() => checkIfNeededAmountAndPackedAmountAreEqual(currentItemNumberState)}>ok</KeypadButton>
                                :
                                <>
                                  {/* <KeypadButton key={13} onClick={() => ok(9999, item.serialNumberCounter)}>proceed</KeypadButton> */}
                                  <KeypadButton key={13} onClick={() => proceed(item.serialNumberCounter)}>proceed()</KeypadButton>
                                  <p key={key}>{item.neededAmount} x {item.article} [{item.serialNumberCounter}]
                                  </p>
                                </>
                              }
                            </KeypadButtonFlexWrap>
                          </NumBlockDiv>
                          }

                        </div>

                    </>
                )
            })}
        </CurrentArticleDiv>

        {isShowHabeFertig && isShowFinalSendButtonState
        ?
        <UnsortSendClearButton onClick={() => unsortArrayOfPackedArticles()}>unsort/send/clear</UnsortSendClearButton>
        :
        <NextArticleInformationDiv>
          <SortByP color={sortBy ? 'green' : 'red'}>[{sortBy ? 'articleGroup --> article' : 'group --> articleGroup'}]</SortByP>
          <UnderlinedP>{currentItemNumberState} von {arrayOfArticleObjectsToBePackedState.length} Artikeln abgearbeitet [{arrayOfArticleObjectsToBePackedState[currentItemNumberState] && arrayOfArticleObjectsToBePackedState[currentItemNumberState]['serialNumberCounter']}]</UnderlinedP>
          <p>nächste Position: {nextArticleState}</p>
        </NextArticleInformationDiv>
        }

        {
        isShowAckItemsDiv || isShowHabeFertig
        ?
        <AckItemsDiv onClick={() => setIsShowAckItemsDiv(false)}>
          {arrayOfPackedArticlesState.map((item, index) => {
            return (
              <p key={index}>
                {
                  ackItemNumbersArrayState.includes(item[1])
                  ?
                  item[0]
                  :
                  ''
                }
              </p>)})
          }
          
          <PackedArticlesLogDiv onClick={() => toggleLogWindow()}>[X] schließen</PackedArticlesLogDiv>
          {/* <div>
            {arrayOfPackedArticlesState.map((item, index) => {return (<p key={index}>{item}</p>)})}
          </div> */}
          <StringOfPackedArticlesDiv>
            {/* {packedStuffFromLocalStorageState} */}
            {arrayOfPackedArticlesState} 
            {/*  */}
            {/*}.map((item, key) => {
              return (
                <>
                  <p key={key}>{item}</p>
                </>
              )
            })} */}
          </StringOfPackedArticlesDiv>
        </AckItemsDiv>
        :
        <div onClick={() => setIsShowAckItemsDiv(true)}>
          Hier clicken, um bisher gepackte Artikel (Logdaten) [{ackItemNumbersArrayState.length}] anzuzeigen.
        </div>
        }

      </MainDiv>
      {/* {arrayOfPackedArticlesState.map(item => {return(<p>{item}</p>)})} */}


    </div>
  );
}

export default ActualApp;


const KeypadButton = styled.button`
  width: 7rem;
  margin: 2px;
  height: 3.8rem;
  border-radius: 6px;
  font-size: 2rem;
  font-family: 'Courier', monospace;
  font-weight: 900;
  color: #ff9f5d;
  background-color: #111;
`;


const MainDiv = styled.div`
  width: 45vh;
  height: 90%;
  margin: 0 auto;
  padding: 0;
  background-color: #bbb;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CurrentArticleDiv = styled.div`
  background-image: linear-gradient(135deg, #f66d00, #ff9f5d, #e24300);
  width: 95%;
  height: auto;
  margin: .2rem auto;
  border-radius: 5px;
  padding: 0 .6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ArticleInformationDiv = styled.div`
  padding: .2rem;
  margin: 2px;
  border: solid .2px #111111;
  border-radius: 3px;
  display: flex;
  flex-flow: column wrap;
  font-size: 12px;
  font-weight: 600;
  // background-color: deeppink;
`;

const NextArticleInformationDiv = styled.div`
  padding: .2rem;
  margin: 2px;
  border: solid .2px #111111;
  border-radius: 3px;
  display: flex;
  flex-flow: column wrap;
  font-size: 2rem;
  font-weight: 600;
  background-color: #111;
  color: orange;
  overflow: hidden;
`;

const NumBlockDiv = styled.div`
  // padding: 0 auto;
  // width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin: 6px auto;
`;

const FatP = styled.p`
  font-weight: 900;
  font-size: 16px;
  padding: 0;
  margin: 0 auto;
  // background-color: orange;
`;

const NeededAmountDiv = styled.div`
  background-color: darkorange;
  color: #222;
  padding: .6rem;
  width: 20rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: 900;
  font-size: 1.5rem;
  border: 1px solid black;
`;

const NeededArticleDiv = styled.div`
  background-color: darkorange;
  min-width: 30rem;
  border: 3px solid black;
  height: 14vh;
  color: #222;
  padding: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 900;
  overflow: hidden;
  font-size: ${props => props.fontSize}px;
`;


const PackedAmountDiv = styled.div`
  background-color: #222;
  color: orange;
  width: 20rem;
  padding: .6rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: 900;
  font-size: 26px;
`;

const KeypadButtonFlexWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
  margin: auto;
  width: 22rem; 
`;

const HabeFertigDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 12px;
  background-color: #123456;
`;

const AckItemsDiv = styled.div`
  font-size: 12px;
  font-family: 'Liberation', Mono;
  background-color: orange;
  padding: 10px;
  margin: 10px auto;
  width: auto;
  display: flex;
  flex-direction: column;
  text-align: left;
  & > div {
    text-align: center;
  }
`;

const SortByP = styled.p`
  color: ${props => props.color};
`

const PackedArticlesLogDiv = styled.div`
  padding: 10px;
  margin: 10px auto;
  border: 2px dotted black;
`

const StringOfPackedArticlesDiv = styled.div`
  white-space: pre-line;
  display: flex;
  text-align: start !important;
  font-size: 9px;
`

const UnderlinedP = styled.p`
  text-decoration: underline;
`

const BoldP = styled.p`
  font-size: 20px;
  font-weight: 700;
`;

const UnsortSendClearButton = styled.button`
  width: 20rem;
  height: 4rem;
  color: green;
  background-image: linear-gradient(135deg, #eee, #ddd);
`